import { getMediaSrc } from '@internal/utils/media/getMediaSrc'
import { Search } from '@mui/icons-material'
import { Button, MobileOverlay } from '@renderer-ui-library/atoms'
import { DynamicTrustPilot } from '@renderer-ui-library/molecules'
import { Container, Typography } from '@renderer-ui-library/mui'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { BlockProps } from 'blocks/BlockProps'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { ISearchCoverBlockData } from './ISearchCoverBlockData'
import { SearchCoverBlockFields } from './SearchCoverBlockFields/SearchCoverBlockFields'
import styles from './searchCoverBlock.module.scss'

export const SearchCoverBlock: React.FC<BlockProps<ISearchCoverBlockData>> =
  React.memo((props) => {
    const { t } = useTranslation(localeNamespace.common)
    const { isMobile } = useMediaQuery()
    const [isMobileOverlayVisible, setMobileOverlayVisibility] = useState(false)
    const showMobileOverlay = useCallback(
      () => setMobileOverlayVisibility(true),
      []
    )
    const hideMobileOverlay = useCallback(
      () => setMobileOverlayVisibility(false),
      []
    )

    const textColor = props.data.colorOverlay === 'dark' ? 'white' : undefined

    return (
      <BlockWrapper
        blockData={props.data}
        noSpacingTop
        noSpacingBottom
        fullWidth={props.rank === 0 && !props.data.narrow}
      >
        <div className={styles.wrapper}>
          {props.data.media && props.data.media.files.data && (
            <div className={styles.mediaWrapper}>
              {props.data.media.files.data.map((file, index) => (
                <img
                  loading='eager'
                  key={index}
                  alt=''
                  {...getMediaSrc(
                    { data: { ...file } },
                    {
                      ratio: 'original',
                      isExtraLarge: true,
                    }
                  )}
                />
              ))}
            </div>
          )}

          <div
            className={classNames(styles.contentWrapper, {
              [styles.colorOverlayDark]: props.data.colorOverlay === 'dark',
              [styles.colorOverlayBright]: props.data.colorOverlay === 'bright',
              [styles.narrow]: props.rank !== 0 || props.data.narrow,
            })}
          >
            <Container maxWidth='lg'>
              <div className={styles.elementsWrapper}>
                <div className={styles.headlines}>
                  <Typography
                    variant='h1'
                    color={textColor}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {props.data.title}
                  </Typography>
                  {props.data.subtitle && (
                    <Typography variant='subtitle1' color={textColor}>
                      {props.data.subtitle}
                    </Typography>
                  )}
                </div>
                <div className={styles.search}>
                  {!isMobile && (
                    <div className={styles.desktopFieldsWrapper}>
                      <SearchCoverBlockFields
                        brand={props.data.brand ?? null}
                        mappedModel={props.data.model ?? null}
                        machineType={props.data.machineType}
                        colorOverlay={props.data.colorOverlay}
                        placement='search-cover-block'
                      />
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <Button
                        size='large'
                        color='secondary'
                        fullWidth
                        startIcon={<Search />}
                        onClick={showMobileOverlay}
                      >
                        {t(translations.searchCoverBlockButtonTextFindOffers)}
                      </Button>

                      {createPortal(
                        <div
                          className={classNames(styles.mobileFilterWrapper, {
                            [styles.mobileFilterWrapperOpen]:
                              isMobileOverlayVisible,
                          })}
                        >
                          <MobileOverlay
                            open={isMobileOverlayVisible}
                            title={t(
                              translations.searchCoverBlockButtonTextFindOffers
                            )}
                            onCloseClick={hideMobileOverlay}
                          >
                            <SearchCoverBlockFields
                              brand={props.data.brand ?? null}
                              mappedModel={props.data.model ?? null}
                              machineType={props.data.machineType}
                              colorOverlay={OverlayColor.Bright}
                              placement='search-cover-block'
                            />
                          </MobileOverlay>
                        </div>,
                        document.body
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={styles.trustPilot}>
                <DynamicTrustPilot
                  locale={props.locale}
                  variant='small'
                  theme={props.data.colorOverlay === 'dark' ? 'dark' : 'light'}
                />
              </div>
            </Container>
          </div>
        </div>
      </BlockWrapper>
    )
  })

SearchCoverBlock.displayName = 'SearchCoverBlock'
